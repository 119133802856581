import { render, staticRenderFns } from "./AuthBasicLayout.vue?vue&type=template&id=c46b3308&scoped=true&"
import script from "./AuthBasicLayout.vue?vue&type=script&lang=js&"
export * from "./AuthBasicLayout.vue?vue&type=script&lang=js&"
import style0 from "./AuthBasicLayout.vue?vue&type=style&index=0&id=c46b3308&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c46b3308",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VCol,VContainer,VIcon,VImg,VMain,VRow})
